import styled from "styled-components"

export const FooterContainer = styled.div`
  width: 100vw;
  margin-bottom: 1em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  a {
    color: ${({ theme }) => theme.textColor};
  }
  @media (${({ theme }) => theme.mediaMedium}) {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`
export const CopyrightContainer = styled.div`
  text-align: center;
`
export const LinksContainer = styled.div``
