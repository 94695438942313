import React from "react"
import logoDark from "../../images/logoDark.svg"
import * as S from "./style"
import { Link } from "gatsby"
import { css } from "styled-components"
import { HomeCircle } from "styled-icons/boxicons-solid"

const Header = () => {
  return (
    <S.HeaderContainer>
      <Link
        to="/"
        css={css`
          color: ${({ theme }) => theme.textColor};
          align-self: center;
          width: inherit;
          height: auto;
        `}
      >
        <S.LogoContainer srcSet={logoDark} alt="Logo Mathias Oviève" />
      </Link>
      <S.MenuContainer>
        <Link
          to="/"
          css={css`
            color: ${({ theme }) => theme.textColor};
            margin-right: 1em;
            align-self: center;
          `}
        >
          <HomeCircle size="1.5em" title="link to home" />
        </Link>
        <S.MenuButton>
          <Link to="/bio/">Bio</Link>
        </S.MenuButton>
        <S.MenuButton>
          <a
            href="https://geeklecode.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </a>
        </S.MenuButton>
        <S.MenuButton>
          <Link to="/contact/">Contact</Link>
        </S.MenuButton>
      </S.MenuContainer>
    </S.HeaderContainer>
  )
}

export default Header
