import styled from "styled-components"

export const HeaderContainer = styled.header`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center + safe;
  align-items: center;
  //padding: 1em 1em;
  top: 0;

  @media (${({ theme }) => theme.mediaSmall}) {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
    padding: 0 10px;
  }
`
export const MenuContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0.3em;
  @media (${({ theme }) => theme.mediaMedium}) {
    padding: 0;
  }
`

export const LogoContainer = styled.img`
  max-width: 200px;
  display: block;
  margin: 0 auto;
  @media (${({ theme }) => theme.mediaSmall}) {
    margin: 0 auto 0 0;
  }
  @media (${({ theme }) => theme.mediaMedium}) {
    max-width: 300px;
  }
  @media (${({ theme }) => theme.mediaLarge}) {
    max-width: 500px;
  }
`

export const MenuButton = styled.button`
  margin-right: 1em;
  width: fit-content;
  height: fit-content;
  background-color: transparent;
  color: ${({ theme }) => theme.textColor};
  border: 2px solid ${({ theme }) => theme.textColor};
  border-radius: 50px;
  font-size: 1em;
  //box-shadow: 0 4px 4px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  & :nth-last-child(1) {
    margin-right: 0;
  }
  & :focus {
    outline: 0;
  }
  & a {
    color: ${({ theme }) => theme.textColor};
    text-decoration: none;
    cursor: pointer;
  }
`
