export const colors = {
  //for dark theme:
  maastrichtBlue: "#0B132B", //background ?
  yankeesBlue: "#1C2541",
  independence: "#3A506B",
  seaSerpent: "#5BC0BE",
  aquamarine: "#6FFFE9",

  //for light theme
  antiflashWhite: "#F2F3F4",
  pearlAqua: "#84DCC6",
  magicMint: "#A5FFD6",
  rackley: "#568EA3",
  seaSerpentLight: "#68C3D4",
}

export const boxShadow = {
  boxShadowLevel1: "0 6px 10px rgba(0, 0, 0, 0.6)",
}
export const mediaQueries = {
  mediaSmall: "min-width: 568px",
  mediaMedium: "min-width: 768px",
  mediaLarge: "min-width: 992px",
  mediaExtraLarge: "min-width: 1200px",
}

export const lightTheme = {
  backgroundColor: colors.antiflashWhite,
  textColor: colors.maastrichtBlue,
  divider1: colors.pearlAqua,
  divider2: colors.rackley,
  divider3: colors.magicMint,
}

export const darkTheme = {
  backgroundColor: colors.maastrichtBlue,
  textColor: colors.antiflashWhite,
  divider1: "#1C2541", //uigradients Dark Ocean
  divider2: colors.seaSerpent,
  divider3: colors.independence,
  serviceCircle: "#5bc0be",
  serviceBackground: "rgba(58,80,107, 0.8)",
}

export const theme = { ...colors, ...boxShadow, ...mediaQueries }

export default isDarkMode =>
  isDarkMode ? { ...theme, ...darkTheme } : { ...theme, ...darkTheme }
