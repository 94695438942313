import React from "react"
import { Link } from "gatsby"
import * as S from "./style"

const Footer = () => {
  return (
    <S.FooterContainer>
      <S.CopyrightContainer>
        {" "}
        © {new Date().getFullYear()} Mathias OVIEVE, built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </S.CopyrightContainer>
      <div>
        <Link to="/mentions-legales/">Mentions Légales</Link>
      </div>
    </S.FooterContainer>
  )
}

export default Footer
